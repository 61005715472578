/* src/index.css or src/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* App.css or styles.css */

/* Apply light red color to the entire page */
body {
  margin: 0;
  padding: 0;
  background-color: #ffcccc; /* Light red color */
}

/* Reset default margin/padding and box model for all elements */
* {
  box-sizing: border-box;
}

/* Style the hero image to be centered */
img {
  display: block;
  margin: 0 auto;
}

