/* projects.css */

/* Use grid for cleaner card alignment */
.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 50px;
  justify-items: center;
  overflow: hidden;
}

.project-card {
  width: 320px;
  height: 380px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff96;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  backdrop-filter: blur(20px);
}

.project-card:hover {
  transform: translateY(-10px); /* Lift effect */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.project-image {
  width: 200px;
  height: 240px;
  border-radius: 20px;
  margin: 0 auto 20px;
  object-fit: cover;
}

.project-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333333; /* Softer color */
  margin: 0;
  transition: color 0.3s ease;
}

.project-card:hover .project-title {
  color: #ff0000; /* Apple-style blue accent on hover */
}

/* Responsive layout adjustments */
@media screen and (max-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 30px;
  }

  .project-card {
    width: 250px;
    height: 350px;
    padding: 15px;
  }

  .project-image {
    width: 180px;
    height: 200px;
  }

  .project-title {
    font-size: 1.5rem;
  }
}
