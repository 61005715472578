/* Body styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: radial-gradient(circle, #ffcccc, #ff9999); /* Radial gradient background */
}

/* About container */
.about-container {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  align-items: center; /* Center vertically */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* About card */
.about-card {
  width: 600px;
  padding: 20px;
  margin-left: -50px;
  border-radius: 20px;
  background-color: #ffffff96;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow */
  backdrop-filter: blur(20px);
}

.about-card h2 {
  font-size: 2.5rem;
  font-weight: bolder;
  margin-bottom: 10px;
  color: #ff0000;
}

.about-card p {
  font-weight: bold;
  color: #ff0000;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  display: block;
  font-weight: bolder;
  margin-bottom: 10px;
  color: #ff0000;
  text-decoration: none;
}

.social-links a:hover {
  background-color: none;
  text-decoration: none;
}

/* GIF image */
.gif-image {
  padding-top: 20px;
  width: 600px; /* Adjust size as needed */
}

.gif-image img {
  width: 100%;
  border-radius: 10px;
}

/* Media queries for responsiveness */

/* For tablets or smaller screens */
@media screen and (max-width: 1024px) {
  .about-container {
      flex-direction: column; /* Stack the content vertically */
      padding: 30px;
  }

  .about-card {
      width: 90%; /* Adjust card width to take more space */
      margin-left: 0; /* Center the card */
  }

  .gif-image {
      width: 90%; /* Adjust gif width for smaller screens */
      margin-top: 20px;
  }

  .about-card h2 {
      font-size: 2rem; /* Adjust heading size */
  }

  .about-card p {
      font-size: 1rem; /* Adjust text size */
  }
}

/* For mobile screens */
@media screen and (max-width: 768px) {
  .about-card {
      width: 100%; /* Take full width */
      margin-left: 0;
      padding: 15px; /* Reduce padding */
  }

  .gif-image {
      width: 100%;
  }

  .about-card h2 {
      font-size: 1.8rem; /* Smaller heading for mobile */
  }

  .about-card p {
      font-size: 0.9rem; /* Smaller text size for mobile */
  }
}

/* For extra-small screens */
@media screen and (max-width: 480px) {
  .about-card {
      width: 100%;
      padding: 10px; /* Further reduce padding for extra small screens */
  }

  .about-card h2 {
      font-size: 1.5rem; /* Further reduce heading size */
  }

  .about-card p {
      font-size: 0.8rem; /* Further reduce text size */
  }

  .gif-image {
      width: 100%; /* Make sure GIF takes full width */
  }
}
